import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";

import Icon from "./Icon";
import { UserType } from "../../types/user";
import { GameStatus, Status } from "../../types/game";
import { getTeamInformation } from "../../services/lobby";
import { Firebase } from "../../managers/FirebaseManager";

const Lobby = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState<UserType[]>([]);

  const watchUserList = () => {
    return Firebase.onValue("/users", (snap) => {
      setUserList(Object.values(snap.val()));
    });
  };

  const watchAdminStart = () => {
    return Firebase.onValue("/gameStatus", (snap) => {
      const { status } = snap.val() as GameStatus;

      status === Status.ON && navigateToBattle();
    });
  };

  const navigateToBattle = async () => {
    const teamResponse = await getTeamInformation();

    if (!teamResponse) return;

    if (teamResponse?.userData?.isPilot) {
      navigate("/battle");
    } else {
      navigate("/preview");
    }
  };

  useEffect(() => {
    watchUserList();
    watchAdminStart();

    return () => {
      Firebase.off("/users");
      Firebase.off("/gameStatus");
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.usersContainer}>
        <h2>Waiting the admin start the round...</h2>
        <h3>Connected users</h3>
        <ul className={styles["usersContainer__list"]}>
          {userList.map((item) => {
            return (
              <li
                key={item.id}
                style={{ animationDelay: Math.random() * 1 + "s" }}
              >
                <Icon />
                <span>{item.name}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Lobby;
