import { useEffect, useState } from "react";
import { Context, IState } from "./context/ideContext";

import {
  cssDefaultTemplate,
  htmlDefaultTemplate,
} from "./config/editorOptions";

import { router } from "./Router";
import { RouterProvider } from "react-router-dom";
import { Firebase } from "./managers/FirebaseManager";

const initialState: IState = {
  html: htmlDefaultTemplate,
  css: cssDefaultTemplate,
};

function App() {
  const [state, dispatch] = useState(initialState);

  useEffect(() => {
    Firebase.init();
  }, []);

  return (
    <Context.Provider value={{ state, dispatch }}>
      <RouterProvider router={router} />
    </Context.Provider>
  );
}

export default App;
