import { useContext, useEffect } from "react";

import { CodeEditor } from "../../components";

import styles from "./index.module.css";
import { Context } from "../../context/ideContext";
import Preview from "../../components/Preview";
import { UserType } from "../../types/user";
import { useNavigate } from "react-router-dom";
import { Firebase } from "../../managers/FirebaseManager";
import { getTeamInformation } from "../../services/lobby";
import { setCode } from "../../services/game";
import { GameStatus } from "../../types/game";
import { Status } from "../../types/game";

const Ide = () => {
  const { state, dispatch } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    watchAdminChangeRole();
    watchAdminChangeStatus();
  }, []);

  const watchAdminChangeRole = async () => {
    const teamResponse = await getTeamInformation();

    if (!teamResponse) return;

    return Firebase.onValue(teamResponse.teamPath, (snap) => {
      const user = snap.val() as UserType;
      navigateUser(user);
    });
  };

  const watchAdminChangeStatus = async () => {
    return Firebase.onValue("gameStatus", (snap) => {
      const { status } = snap.val() as GameStatus;

      if (status !== Status.ON) {
        navigate("/lobby");
      }
    });
  };

  const navigateUser = (user: UserType) => {
    console.log(user);
    if (user?.isPilot) {
      navigate("/battle");
    } else {
      navigate("/preview");
    }
  };

  const handleOnHtmlChange = (value: string) => {
    dispatch((prevState) => ({
      ...prevState,
      html: value || "",
    }));
  };

  const handleOnSavePress = () => {
    //TODO: Adicionar validador pra impedir o jogador de pressionar e enviar varias vezes
    setCode(state.html, state.css);
  };

  return (
    <div className={styles.App}>
      <>
        <div className={styles.ideContainer}>
          <CodeEditor
            onChange={handleOnHtmlChange}
            title="Html"
            type="html"
            value={state.html}
          />
          <CodeEditor
            title="CSS"
            type="css"
            value={state.css}
            onChange={(value) =>
              dispatch((prevState) => ({
                ...prevState,
                css: value || "",
              }))
            }
          />
        </div>
        <Preview />
        <button onClick={handleOnSavePress} className={styles.timer}>
          Save
        </button>
      </>
    </div>
  );
};

export default Ide;
