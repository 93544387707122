import { useEffect, useReducer, useState } from "react";
import Card from "./Card";
import styles from "./index.module.css";
import { Firebase } from "../../managers/FirebaseManager";
import { RoundType, TeamCode } from "../../types/user";
import ReactModal from "react-modal";
import Preview from "./Preview";
import useSound from "use-sound";
import sound from "../../assets/sound/confirm.mp3";

const test = [
  {
    code: '<div class="container"><button id="btn">Ola galera</button></div>',
    style:
      ".container {display: flex; flex: 1; height: 100vh; width: 100vw; justify-content: center; align-items: center; background-color: #313866;} #btn {background-color: #EA1179; width: 200px; height: 40px; border-style: none; border-radius: 2px; color: #F1F0E8;}",
    name: "test",
  },
  {
    code: '<div class="example"><p>Hello, World!</p></div>',
    style:
      ".example {background-color: #F0F0F0; padding: 20px; text-align: center;} .example p {font-size: 18px; color: #333;}",
    name: "example1",
  },
  {
    code: '<div class="box"><h1>My Website</h1><p>Welcome to my website!</p></div>',
    style:
      ".box {background-color: #FFF; border: 1px solid #DDD; padding: 20px; text-align: center;} .box h1 {font-size: 24px; color: #333;} .box p {font-size: 16px; color: #666;}",
    name: "website",
  },
  {
    code: '<div class="header"><h1>Header</h1></div>',
    style:
      ".header {background-color: #0073e6; padding: 10px; text-align: center;} .header h1 {font-size: 24px; color: #FFF;}",
    name: "header",
  },
  {
    code: '<div class="footer"><p>&copy; 2023 My Website</p></div>',
    style:
      ".footer {background-color: #333; padding: 10px; text-align: center;} .footer p {font-size: 14px; color: #FFF;}",
    name: "footer",
  },
  {
    code: '<div class="sidebar"><ul><li>Home</li><li>About</li><li>Contact</li></ul></div>',
    style:
      ".sidebar {background-color: #333; padding: 20px;} .sidebar ul {list-style-type: none; padding: 0;} .sidebar li {font-size: 16px; color: #FFF; margin-bottom: 10px;}",
    name: "sidebar",
  },
  {
    code: '<div class="content"><h2>About Us</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p></div>',
    style:
      ".content {padding: 20px;} .content h2 {font-size: 24px; color: #333;} .content p {font-size: 16px; color: #666;}",
    name: "about",
  },
  {
    code: '<div class="contact"><h2>Contact Us</h2><p>Email: contact@example.com</p><p>Phone: 123-456-7890</p></div>',
    style:
      ".contact {padding: 20px;} .contact h2 {font-size: 24px; color: #333;} .contact p {font-size: 16px; color: #666;}",
    name: "contact",
  },
  {
    code: '<div class="featured"><h2>Featured Product</h2><p>Check out our latest product!</p></div>',
    style:
      ".featured {padding: 20px; background-color: #FFF; border: 1px solid #DDD;} .featured h2 {font-size: 24px; color: #333;} .featured p {font-size: 16px; color: #666;}",
    name: "featured",
  },
];

const Validation = () => {
  const [play] = useSound(sound);
  const [waiting, setWaiting] = useState(true);
  const [teams, setTeams] = useState<any[]>([]);
  const [gameStatus, setGameStatus] = useState<any>({});
  const [teamSelected, setTeamSelected] = useState<any>();
  const [isModalVisible, toggleIsModalVisible] = useReducer(
    (state) => !state,
    false
  );

  useEffect(() => {
    loadTeams();
    watchGameStatus();

    return () => Firebase.off("/gameStatus");
  }, []);

  const watchGameStatus = () => {
    return Firebase.onValue("/gameStatus", (snap) => {
      const status = snap.val().status;
      if (status === "FINISHED") {
        setWaiting(false);
      } else {
        setWaiting(true);
      }
    });
  };

  const loadTeams = async () => {
    const gameStatusResponse = (await Firebase.get("gameStatus/"))?.val();
    if (gameStatusResponse?.round) {
      setGameStatus(gameStatusResponse);
      const rounds = (
        await Firebase.get(`rounds/${gameStatusResponse.round}`)
      )?.val();
      if (rounds?.teams) setTeams(Object.values(rounds.teams));
    }
  };

  const handleOnClick = (index: number) => {
    const teamSelected = teams[index];

    setTeamSelected(teamSelected);
    toggleIsModalVisible();
  };

  const handleOnClosePress = () => {
    toggleIsModalVisible();
    setTeamSelected({});
  };

  const setVote = async (team: any) => {
    const res = await Firebase.get(`ranking/${gameStatus.round}`);
    if (res?.val() !== null) {
      const teamPoints = res?.val()[team.teamId];
      Firebase.set(`ranking/${gameStatus.round}`, {
        [team.teamId]: teamPoints + 1,
      });
    } else {
      Firebase.set(`ranking/${gameStatus.round}`, {
        [team.teamId]: 1,
      });
    }

    play();
  };

  const handleOnConfirmPress = () => {
    const teamLiked = teams.find((item) => item["status"] === "liked");

    if (teamLiked) {
      setVote(teamLiked);
    } else {
      alert("Vote em algum time");
    }
  };

  const handleOnCardVoting = (type: "liked" | "disliked") => {
    const index = teams.findIndex((item) => teamSelected?.name === item.name);
    Object.assign(teams[index], { status: type });

    setTeams(teams);

    toggleIsModalVisible();
  };

  return (
    <div className={styles.container}>
      {waiting ? (
        <h1>Carregando</h1>
      ) : (
        <>
          <div className={styles.teamContainer}>
            {teams?.map((item, index) => (
              <Card
                onClick={() => handleOnClick(index)}
                name={item.name}
                status={item?.status}
              />
            ))}
          </div>

          <ReactModal
            onRequestClose={handleOnClosePress}
            style={{ content: { padding: 0 } }}
            isOpen={isModalVisible}
            ariaHideApp={false}
          >
            <Preview
              onLikePress={() => handleOnCardVoting("liked")}
              onUnlikePress={() => handleOnCardVoting("disliked")}
              teamSelected={teamSelected}
            />
          </ReactModal>
          <button
            onClick={handleOnConfirmPress}
            className={styles.confirmButton}
          >
            Confirmar
          </button>
        </>
      )}
    </div>
  );
};
export default Validation;
