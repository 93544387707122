import { Firebase } from "../managers/FirebaseManager";
import { UserType } from "../types/user";

export const getTeamInformation = async () => {
  const userStored = localStorage.getItem("user");

  // TODO: Adicionar validação aqui
  if (!userStored) return;

  const { code } = JSON.parse(userStored);

  const responseTeams = (await Firebase.get(`users/${code}`).then((snap) =>
    snap?.val()
  )) as UserType;

  if (!responseTeams) return;

  const teamPath = `teams/${responseTeams.teamId}/${code}`;

  const userTeamInfo = (await Firebase.get(teamPath).then((snap) =>
    snap?.val()
  )) as UserType;

  return { userData: userTeamInfo, teamPath: teamPath };
};
