import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../types/user";
import { getTeamInformation } from "../../services/lobby";
import { Firebase } from "../../managers/FirebaseManager";
import { getGameStatus, getRoundInformation } from "../../services/game";
import { GameStatus, Status } from "../../types/game";

const Preview = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");

  useEffect(() => {
    watchAdminChangeRole();
    watchAdminChangeStatus();
    loadImage();
  }, []);

  const loadImage = async () => {
    const gameStatus = await getGameStatus();
    const round = await getRoundInformation(gameStatus.round);

    setImage(round.image_url);
  };

  const watchAdminChangeStatus = async () => {
    return Firebase.onValue("gameStatus", (snap) => {
      const { status } = snap.val() as GameStatus;

      if (status !== Status.ON) {
        navigate("/lobby");
      }
    });
  };
  const watchAdminChangeRole = async () => {
    const teamResponse = await getTeamInformation();

    if (!teamResponse) return;

    return Firebase.onValue(teamResponse.teamPath, (snap) => {
      const user = snap.val() as UserType;
      navigateUser(user);
    });
  };

  const navigateUser = (user: UserType) => {
    if (user?.isPilot) {
      navigate("/battle");
    } else {
      navigate("/preview");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <img src={image} />
      </div>
    </div>
  );
};

export default Preview;
