import styles from "./index.module.css";

type CardProps = {
  name: string;
  onClick: () => void;
  status: "liked" | "disliked" | undefined;
};

const Card = ({ name, onClick, status }: CardProps) => {
  const isLiked = status === "liked";

  return (
    <button
      onClick={onClick}
      className={isLiked ? styles.containerSelected : styles.container}
    >
      <h1>{name}</h1>
    </button>
  );
};

export default Card;
