
export enum Status {
  OFF = 'OFF',
  ON = 'ON',
  WAITING = 'WAITING',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED'
}

export type GameStatus = { 
  round: number;
  roundTime: number;
  status: Status;
  totalOfRound: number;
}