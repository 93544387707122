import { useEffect, useState } from "react";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import { getUserByCode } from "../../utils/validator";
import { registerNewUser } from "../../services/login";
import QrCodeScanner from "../../components/QrCodeScanner";

const Login = () => {
  const navigate = useNavigate();
  const [userCode, setUserCode] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    userCode.length >= 4 ? setIsButtonEnabled(true) : setIsButtonEnabled(false);
  }, [userCode]);

  const validateUserCode = async () => {
    const user: any = await getUserByCode(userCode);

    if (!!user) {
      fetchLogin(user?.name);
    } else {
      alert("Código inválido");
    }
  };

  const fetchLogin = async (username: string) => {
    try {
      await registerNewUser(username, userCode);
      navigate("/lobby", { replace: true });
    } catch (err: any) {
      alert(err);
    }
  };

  const handleFoundQrCode = (data: string) => {
    // TODO:
    setUserCode(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.cameraBox}>
        {/* <QrCodeScanner handleQrCode={handleFoundQrCode} /> */}
      </div>
      <div className={styles.loginBox}>
        <input
          type="text"
          value={userCode}
          onChange={(value) => setUserCode(value.target.value)}
          placeholder="Codigo"
        />
        <button
          className={isButtonEnabled ? styles.buttonOn : styles.buttonOff}
          onClick={validateUserCode}
          disabled={!isButtonEnabled}
        >
          Confirmar
        </button>
      </div>
    </div>
  );
};

export default Login;
