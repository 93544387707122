import { Firebase } from "../managers/FirebaseManager";
import { GameStatusType, UserType } from "../types/user";

export const setCode = async (html: string, style: string) => {
  const userStored = localStorage.getItem("user");

  // TODO: Adicionar validação aqui
  if (!userStored) return;

  const { code } = JSON.parse(userStored);

  const currentRound = (await Firebase.get("/gameStatus").then((snap) =>
    snap?.val()
  )) as GameStatusType;

  const { teamId } = (await Firebase.get(`users/${code}`).then((snap) =>
    snap?.val()
  )) as UserType;

  await Firebase.set(`rounds/${currentRound.round}/teams/${teamId}/${code}`, {
    code: html,
    style,
  });
};

export const getGameStatus = async () => {
  const gameStatus = await Firebase.get("gameStatus/").then((snap) =>
    snap?.val()
  );

  return gameStatus;
};

export const getRoundInformation = async (currentRound: number) => {
  const round = await Firebase.get(`rounds/${currentRound}`);

  return round?.val();
};
