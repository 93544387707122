import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import Ide from "./pages/Ide";
import Lobby from "./pages/Lobby/";
import Preview from "./pages/Preview";
import ProtectedRoute from "./components/ProtectedRoute";
import Validation from "./pages/Validation";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "battle",
    element: (
      <ProtectedRoute>
        <Ide />
      </ProtectedRoute>
    ),
  },
  {
    path: "lobby",
    element: (
      <ProtectedRoute>
        <Lobby />
      </ProtectedRoute>
    ),
  },
  {
    path: "preview",
    element: (
      <ProtectedRoute>
        <Preview />
      </ProtectedRoute>
    ),
  },
  {
    path: "validation",
    element: <Validation />,
  },
]);
