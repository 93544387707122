import { Firebase } from "../managers/FirebaseManager";
import { UserType } from "../types/user";
import { getUserByCode } from "../utils";

export const registerNewUser = async (userName: string, code: string) => {
  try {
    const userInformation = await getUserByCode(code);

    if (userInformation) {
      const userObject: UserType = {
        id: code,
        name: userInformation.name,
        userName: `${userInformation.nameTranslated}  (${userInformation.name})`,
        teamId: "",
      };

      Firebase.set(`users/${code}`, userObject);

      const user = JSON.stringify({ code, userName: userInformation.name });

      localStorage.setItem("user", user);
    }
  } catch (error) {
    throw error;
  }
};

export const checkIfUserAlreadyExists = async (code: string) => {
  const userResponse = await Firebase.get("/users");

  if (!userResponse) return false;

  const listOfUsers: UserType[] = Object.values(userResponse);
  const userExists = !!listOfUsers.find((item) => item.id === code);

  return userExists;
};
