import { createContext } from "react";

import { Dispatch, SetStateAction } from "react";

export interface IState {
  html: string;
  css: string;
}

export interface IContext {
  state: IState;
  dispatch: Dispatch<SetStateAction<IState>>;
}

export const Context = createContext<IContext>(null as any);
