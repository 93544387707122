import { editorOptions } from "../../config/editorOptions";
import styles from "./index.module.css";
import Editor from "@monaco-editor/react";
import theme from "monaco-themes/themes/Night Owl.json";

import HtmlIcon from "../../assets/images/html.svg";
import CssIcon from "../../assets/images/css.svg";

type CodeEditorProps = {
  title: string;
  onChange: (text: string) => void;
  type: "html" | "css";
  value: string;
};

export const CodeEditor = ({
  onChange,
  title,
  type,
  value,
}: CodeEditorProps) => {
  function handleEditorDidMount(editor: any, monaco: any) {
    monaco.editor.defineTheme("myTheme", theme);
    monaco.editor.setTheme("myTheme");
  }

  return (
    <div className={styles.container}>
      <div className={styles.tag}>
        {type === "html" ? (
          <img src={HtmlIcon} alt="" />
        ) : (
          <img src={CssIcon} alt="" />
        )}
        <h3>{title}</h3>
      </div>
      <Editor
        defaultLanguage={type}
        options={editorOptions}
        defaultValue={value}
        theme="myTheme"
        // @ts-ignore
        onChange={onChange}
        onMount={handleEditorDidMount}
      />
    </div>
  );
};
