import {
  cssDefaultTemplate,
  htmlDefaultTemplate,
} from "../../../config/editorOptions";
import styles from "./index.module.css";

type PreviewProps = {
  teamSelected: any;
  onLikePress: () => void;
  onUnlikePress: () => void;
};

const Preview = ({
  teamSelected,
  onUnlikePress,
  onLikePress,
}: PreviewProps) => {
  // @ts-expect-error
  const firstHtmlCode = Object.values(teamSelected)?.[0]?.code;
  // @ts-expect-error
  const firstCssCode = Object.values(teamSelected)?.[0]?.style;

  // @ts-expect-error
  const secondHtmlCode = Object.values(teamSelected)?.[1]?.code;
  // @ts-expect-error
  const secondCssCode = Object.values(teamSelected)?.[1]?.style;

  const firstCode = `
  <html>
    <style> ${firstCssCode}</style>
    <body>${firstHtmlCode}</body>
    </html>
  `;
  const secondCode = `
  <html>
    <style> ${secondCssCode}</style>
    <body>${secondHtmlCode}</body>
    </html>
  `;

  return (
    <div className={styles.container}>
      <iframe
        srcDoc={firstCode}
        title="output"
        sandbox="allow-scripts"
        scrolling="no"
        className={styles.previewContainer}
      />
      <iframe
        srcDoc={secondCode}
        title="output"
        sandbox="allow-scripts"
        scrolling="no"
        className={styles.previewContainer}
      />
      <div className={styles.buttonRow}>
        <button onClick={onUnlikePress}>👎</button>
        <button onClick={onLikePress}>👍</button>
      </div>
    </div>
  );
};

export default Preview;
