import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import {
  DataSnapshot,
  Database,
  get,
  getDatabase,
  off,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

class FirebaseManager {
  firebase: FirebaseApp | undefined = undefined;
  database: Database | undefined = undefined;
  auth: Auth | undefined = undefined;

  init() {
    const init = initializeApp(firebaseConfig);
    this.firebase = init;
    this.database = getDatabase();
    this.auth = getAuth(init);
  }

  async remove(child: string) {
    if (this.database) await remove(ref(this?.database, child));
  }

  async get(child: string) {
    if (this.database) return await get(ref(this.database, child));
  }

  async set(child: string, data: {}) {
    if (this.database) return await set(ref(this.database, child), data);
  }

  async update(child: string, data: {}) {
    if (this.database) return await update(ref(this.database, child), data);
  }

  async push(child: string, data?: {}) {
    if (this.database) return await push(ref(this.database, child), data);
  }

  onValue(child: string, callback: (snap: DataSnapshot) => void) {
    if (this.database) return onValue(ref(this.database, child), callback);
  }

  off(child: string) {
    if (this.database) return off(ref(this.database, child));
  }
}

export const Firebase = new FirebaseManager();
